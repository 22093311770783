import './src/styles/index.sass'
import { useStore } from 'src/state'

const setScrollPosition = () => {
  const scrollY = window.pageYOffset
  const scrollPct =
    scrollY / (document.documentElement.offsetHeight - window.innerHeight)
  useStore.getState().setScrollY(scrollY)
  useStore.getState().setScrollPct(scrollPct)
}

window.addEventListener('load', setScrollPosition)
window.addEventListener('scroll', setScrollPosition)
window.addEventListener('load', () => window.scrollTo(0, 0))
