import create from 'zustand'

export const useStore = create((set) => ({
  scrollY: 0,
  setScrollY: (scrollY) =>
    set((state) => {
      document.documentElement.style.setProperty('--scrollY', scrollY + 'px')
      return { scrollY: state.scrollY }
    }),
  scrollPct: 0,
  setScrollPct: (scrollPct) =>
    set((state) => {
      document.documentElement.style.setProperty('--scrollPct', scrollPct)
      return { scrollPct: state.scrollPct }
    }),
  shouldShowDrawer: false,
  showDrawer: () =>
    set((state) => {
      return { shouldShowDrawer: true }
    }),
  closeDrawer: () =>
    set((state) => {
      return { shouldShowDrawer: false }
    }),
  drawerContent: null,
  setDrawerContent: (content) =>
    set((state) => {
      return { drawerContent: content }
    }),
}))
